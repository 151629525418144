<template>
  <a-drawer :title="title" width="60%" :placement="placement" :closable="true" :visible="visible" @close="onSwitch(false)" v-if="visible">
    <suy-table :reload="loadData" v-model:columns="columns" >
      <template #search>
        <a-form ref="formRef" layout=inline :model="formNowLocation">
          <a-form-item>
            <a-cascader :options="$store.state.app.cityDict" :changeOnSelect="true" :show-search="{ filter }" @change="setAddress" placeholder="请选择地址" />
          </a-form-item>
          <a-form-item>
            <a-input v-model:value="formNowLocation.details" placeholder="详细地址" />
          </a-form-item>
          <a-form-item>
            <a-button type="primary" html-type="submit" @click="onClick('',1)" v-model:disabled="onArrive">
              新增
            </a-button>
            <a-divider type="vertical" />
            <a-button html-type="submit" @click="onClick('',2)" v-model:disabled="onArrive">
              修改
            </a-button>
            <a-divider type="vertical" />
            <!--            <a-popconfirm title="确定到达?" @confirm="onClick('',3)" v-model:disabled="onArrive">-->
            <!--              <a-button type="primary" class="button_arrive" :class="onArrive?'hui':''" v-model:disabled="onArrive">-->
            <!--                到达-->
            <!--              </a-button>-->
            <!--            </a-popconfirm>-->
            <a-button>请去运车列表中点击到达</a-button>
          </a-form-item>
        </a-form>
      </template>
      <a-table :columns="[sortColumn,...columns]" :loading="loading" :data-source="listData" :pagination="pagination" :rowKey="(record,index)=>{return index}" @change="handleTableChange">
        <template #sort="{ index }">
          {{index+1}}
        </template>

      </a-table>
    </suy-table>
    <div>
      <div class=" suy_bod_b">
        <div class="suy_title textindent">
          <h2>运输路线详情</h2>
        </div>
      </div>
      <div class="suy_table_title">
        <div v-if="transTaskGpsLine === undefined">
          <label>暂无信息</label>
        </div>
        <div v-else>
          <a-row :gutter="[8,32]">
            <a-col :span="8">
              <label>总运输里程（Km）: </label>
              <b>{{transTaskGpsLine.totalTransportKm}}</b>
            </a-col>
            <a-col :span="8">
              <label>预计需时（小时）: </label>
              <b>{{transTaskGpsLine.totalTransportTime}}</b>
            </a-col>
            <a-col :span="8">
              <label>发车时间: </label>
              <b>{{transTaskGpsLine.departureTime}}</b>
            </a-col>
          </a-row>
          <a-row :gutter="[8,32]">
            <a-col :span="8">
              <label>当前位置: </label>
              <b>{{transTaskGpsLine.latestLocation}}</b>
            </a-col>
            <a-col :span="8">
              <label>记录时间（小时）: </label>
              <b>{{transTaskGpsLine.transportTime}}</b>
            </a-col>
            <a-col :span="8">
              <label>已行驶时间（小时）: </label>
              <b>{{transTaskGpsLine.transportTime}}</b>
            </a-col>
          </a-row>

          <a-row :gutter="[8,32]">
            <a-col :span="8">
              <label>系统设置每天应行驶里程（Km）: </label>
              <b>{{transTaskGpsLine.defaultKm}}</b>
            </a-col>
            <a-col :span="8">
              <label>实际剩余公里数（Km）: </label>
              <b>{{transTaskGpsLine.remainingKm}}</b>
            </a-col>
            <a-col :span="8">
              <label>是否超期: </label>
              <a-tag :color="overDueColor">{{transTaskGpsLine.isOverDue.label}}</a-tag>
            </a-col>
          </a-row>
        </div>
      </div>
    </div>
  </a-drawer>
</template>
<script>
import { toRefs, reactive, ref } from 'vue'
import { truckGpsAdd, detail, truckGpsPage, transArrive } from '@/api/transport/truck'
import SuyTable from '@/components/SuyTable'
import { message } from 'ant-design-vue'
export default {
  components: {
    SuyTable
  },
  setup () {
    const state = reactive({
      overDueColor: '#87d068',
      transTaskGpsLine: undefined,
      gpsId: '',
      formRef: ref(),
      carid: '',
      visible: false,
      loading: false,
      onArrive: true,
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      listData: [],
      sortColumn: { title: '序号', slots: { customRender: 'sort' } },
      columns: [
        {
          title: '车架号',
          dataIndex: 'carNo',
          key: 'carNo',
          ellipsis: true

        },
        {
          title: '地址',
          dataIndex: 'location',
          key: 'location',
          ellipsis: true
        },
        {
          title: '操作人员',
          dataIndex: 'operator',
          key: 'operator',
          ellipsis: true
        },
        {
          title: '修改时间',
          dataIndex: 'recordTime',
          key: 'recordTime',
          ellipsis: true
        },
        {
          title: '说明',
          dataIndex: 'remark',
          key: 'remark',
          ellipsis: true
        }
      ],
      formNowLocation: {
        province: ''
      }
    })
    const loadData = async () => {
      state.loading = true
      truckGpsPage(state.carid, {
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then((res) => {
        if (res.code === 10000) {
          if (res.data.records !== []) {
            state.gpsId = res.data.records[0].gpsId
          }
        }
        state.listData = res.data.records
        state.pagination.current = res.data.current
        state.pagination.pageSize = res.data.size
        state.pagination.total = res.data.total
      })
      detail(
        state.carid
      ).then((res) => {
        if (res.code === 10000) {
          if (res.data.truckStatus.value === 2) {
            state.onArrive = false
          } else {
            state.onArrive = true
          }
          if (res.data.gpsLine !== undefined && res.data.gpsLine !== null) {
            state.transTaskGpsLine = res.data.gpsLine
            if (state.transTaskGpsLine.isOverDue.value === 1) {
              state.overDueColor = '#f50'
            }
          } else {
            state.transTaskGpsLine = undefined
          }
        }
      })
      state.loading = false
    }
    const onSwitch = (parameter) => {
      if (parameter === false) {
        state.listData = []
        state.formNowLocation.province = ''
      }
      state.visible = parameter
    }
    const oncarid = (parameter) => {
      state.carid = parameter
      onSwitch(true)
      loadData()
    }
    const onClick = (recode, type) => {
      switch (type) {
        case 1:
          var NowLocation = {
            transTruckId: state.carid,
            addressDTO: state.formNowLocation,
            remark: '手动新增'
          }
          truckGpsAdd(NowLocation).then((res) => {
            if (res.code === 10000) {
              message.success('添加成功')
              loadData()
            }
          })
          state.formRef.resetFields()
          break
        case 2:
          if (state.formNowLocation.province === '') {
            message.error('请选择修改地点')
          } else {
            NowLocation = {
              gpsId: state.gpsId,
              transTruckId: state.carid,
              addressDTO: state.formNowLocation,
              remark: '手动修改'
            }
            truckGpsAdd(NowLocation).then((res) => {
              if (res.code === 10000) {
                message.success('修改成功')
                loadData()
              }
            })
          }

          state.formRef.resetFields()
          break
        case 3:
          transArrive(state.carid, {

          }).then((res) => {
            if (res.code === 10000) {
              message.success(res.msg)
              loadData()
            }
          })
          break
        default:
          message.success('出现错误' + type)
      }
      context.emit('onBIGloadData')
    }
    const handleTableChange = (pag, filters, sorter) => {
      state.pagination.current = pag.current
      state.pagination.pageSize = pag.pageSize
      loadData()
    }
    const setAddress = (value) => {
      state.formNowLocation.province = value[0]
      state.formNowLocation.city = value[1]
      state.formNowLocation.area = value[2]
      if (value[0] === undefined) {
        state.formNowLocation.province = ''
      }
    }
    return {
      oncarid,
      setAddress,
      onSwitch,
      ...toRefs(state),
      loadData,
      handleTableChange,
      onClick
    }
  }
}
</script>
<style lang="less" scoped>
.button_arrive {
  border: 0px;
  background-color: green;
}
.hui {
  background-color: #f5f5f5 !important;
  color: #d9d9d9;
  border: 0;
}
</style>
