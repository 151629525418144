<style lang="less" scoped>
@import './index.less';
</style>

<template>
  <div class="mod-user" :style="tableStyle">
    <div class="suy">
      <div>
        <div class=" suy_bod_b">
          <div class="suy_title textindent">
            <h2>运输任务列表</h2>
          </div>
          <a-form layout="inline" ref="formRef" :model="formState">
            <a-row>
              <a-col :span="6">
                <a-form-item label="">
                  <a-select v-model:value="formState.truckStatus.value" placeholder="运输类型">
                    <a-select-option v-for="city  in dataset" :key="city[1]"> {{ city[0] }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="6">
                <a-form-item label="">
                  <a-input v-model:value="formState.carNo" placeholder="大车车牌" />
                </a-form-item>
              </a-col>
              <a-col :span="6">
                <a-form-item label="">
                  <a-input v-model:value="formState.vehicleNo" placeholder="小车车牌" />
                </a-form-item>
              </a-col>
              <a-col :span="6">
                <a-form-item label="">
                  <a-cascader :options="$store.state.app.cityDict" :show-search="{ filter }" :changeOnSelect="true"
                    @change="setStartAddress" placeholder="起始地点" />
                </a-form-item>
              </a-col>
              <a-col :span="6">
                <a-form-item label="">
                  <a-cascader :options="$store.state.app.cityDict" :show-search="{ filter }" :changeOnSelect="true"
                    @change="setEndAddress" style="height: 38px;" placeholder="目的地点" />
                </a-form-item>
              </a-col>
              <a-col :span="6">
                <a-form-item label="">
                  <a-input v-model:value="formState.driverName" placeholder="大车司机" />
                </a-form-item>
              </a-col>

              <a-col :span="6">
                <a-form-item label="">
                  <a-input v-model:value="formState.carrierName" placeholder="承运名称" />
                </a-form-item>
              </a-col>
              <a-col :span="6">
                <a-form-item label="">
                  <a-input v-model:value="formState.carrierMobile" placeholder="承运电话" />
                </a-form-item>
              </a-col>
              <a-col :span="6">
                <a-form-item label="">
                  <a-input v-model:value="formState.dispatcher" placeholder="派车人" />
                </a-form-item>
              </a-col>
              <a-col :span="6">
                <a-form-item label="">
                  <a-select v-model:value="formState.gpsUpdateStatus" allowClear placeholder="是否更新">
                    <a-select-option v-for="item in whether" :key="item.value"> {{ item.label }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="6">
                <a-form-item label="车类型">
                  <a-radio-group v-model:value="formState.truckType">
                    <a-radio :value="0">全部</a-radio>
                    <a-radio :value="1">大板车</a-radio>
                    <a-radio :value="2">小板车</a-radio>
                    <a-radio :value="4">地跑</a-radio>
                  </a-radio-group>
                </a-form-item>
              </a-col>
              <a-col :span="6" style="text-align: right;">
                <a-form-item>
                  <a-button type="primary" html-type="submit" @click="positionShowModalGPS" class="position">
                    位置
                  </a-button>
                  <a-button style="margin-left: 10px" type="primary" html-type="submit" @click="onClick('', 8)">
                    搜索
                  </a-button>
                  <a-button style="margin-left: 10px" @click="onClick('', 2)">
                    重置
                  </a-button>
                  <a-button style="margin-left: 10px" @click="exportData">
                    导出
                  </a-button>
                </a-form-item>
              </a-col>
            </a-row>

          </a-form>
        </div>

        <div class="suy_table_title_div">
          <div class="suy_table_title_div_R">
            <div class="inoc_div">
              <RedoOutlined @click="loadData" />
            </div>
          </div>
        </div>

        <div class="suy_table_title">

          <a-table :data-source="listData" v-model:columns="columns"
            :rowClassName="(record, index) => (record.gpsUpdateStatus && record.gpsUpdateStatus.value === 0 ? 'table-yellow' : null)"
            :rowKey="(record, index) => { return index }" :pagination="pagination" :scroll="{ x: 10 }" :loading="loading"
            @change="handleTableChange" size="middle">

            <template #operation="{ record }">
              <!--              <a @click="onClick(record,7)">查看</a>-->
              <!--              <a-divider type="vertical" />-->
              <a @click="onClick(record, 9)">路线</a>
              <a-divider type="vertical" />
              <a @click="onClick(record, 10)">位置</a>
            </template>
            <template #carNo="{ record }">
              <span class="d89f8" @click="onClick(record, 7)">{{ record.carNo }}</span>
            </template>
            <template #driver="{ record }">
              <span class="d89f8">姓名：{{ record.driverName }}</span>
              <br />
              <span>电话：{{ record.driverTel }}</span>
            </template>
            <template #Status="{ record }">
              <span
                :class="[(record.truckStatus.value === 0) ? 'f1ab18' : '', (record.truckStatus.value === 1) ? 'd89f8' : '', (record.truckStatus.value === 2) ? 'cb32f' : '', (record.truckStatus.value === 3) ? 'ee5c23' : '', (record.truckStatus.value === 4) ? 'lv' : '', (record.truckStatus.value === 5) ? 'e3e3e3' : '']">
                {{ record.truckStatus.label }}</span>
            </template>
            <template #gpsStatus="{ record }">
              <span v-if="record.gpsStatus != null" :class="(record.gpsStatus.value === 2) ? 'f1ab18' : ''">{{
                record.gpsStatus.label }}</span>
            </template>

            <template #nowLocation="{ record }">
              <a-typography-text v-if="record.nowLocation != null" style="  word-break:break-all" :ellipsis="false"
                :copyable="{ tooltips: false }" :content="record.nowLocation" />
            </template>
          </a-table>
        </div>
      </div>
    </div>

  </div>

  <div v-show="mapShow" class="route-map" id="containerMap" :style="baiduMapStyle"></div>
  <div class="close-btn">
    <!-- <a-button type="primary" v-show="mapShow" @click="lushu.start()">开始</a-button>
    <a-button type="primary" v-show="mapShow" style="margin-left:10px" @click="lushu.pause()">暂停</a-button>
    <a-button type="primary" v-show="mapShow" style="margin-left:10px" @click="lushu.stop()">停止</a-button> -->
    <a-button type="primary" v-show="mapShow" style="margin-left:10px" @click="mapShow=false">关闭地图</a-button>
  </div>
  <plan ref="plan" />
  <position ref="position" @onloadData="loadData" />
  <a-modal v-model:visible="visiblemodal" title="车辆详情" v-if="visiblemodal" @ok="onClick(positions, 11)" footer=""
    width="60%">
    <div>
      <div class="suy_table_title">
        <div>
          <a-button detailsloading="detailsloading" v-if="detailsloading" class="data_button">数据加载中</a-button>
          <div v-else>
            <div v-if="vehicleNo === null">
              <label>暂无信息</label>
            </div>
            <a-row :gutter="[8, 32]" v-else>
              <a-col :span="8">
                <label>小车车牌: </label>
                <b>{{ vehicleNo }}</b>
              </a-col>
            </a-row>

          </div>
        </div>
      </div>
    </div>
  </a-modal>
  <modalGPS ref="modalGPS" />
</template>

<script>
import { message } from 'ant-design-vue'
// import iconCar from '@/assets/images/BigCar.png'
// import iconHYcar from '@/assets/images/HYcar.png'
import { onMounted, reactive, toRefs, ref } from 'vue'
import { page as bigPage } from '@/api/transport/vehicle'
import { tracking, truckPageExport } from '@/api/transport/truck'
// import iconCar from '@/assets/images/.png'
import transportType from '../TransportType/TransportType.json'
import plan from './plan'
import modalGPS from './gps.vue'
import position from './position'
// import orderMp3 from '@/assets/video/order.mp3'
import { RedoOutlined } from '@ant-design/icons-vue'
import dict from '@/config/dict.json'
// import { createLogger } from 'vuex'

export default {
  data () {
    return {
      dataset: transportType.truckStatus
    }
  },
  components: { plan, position, RedoOutlined, modalGPS },
  name: 'gps',

  setup (props) {
    // console.log(widow.document.body.offsetWidth)
    const state = reactive({
      whether: dict.whether,
      carType: 0,
      lushu: undefined,
      vehicleNo: null,
      baiduMapStyle: { height: '' },
      tableStyle: { width: '' },
      newList: [],
      markers: [],
      positions: {
        nowLocation: {
          details: ''
        }
      },
      mapShow: false,
      visiblemodal: false,
      carIMG: ref(null),
      newTask: ref(null),
      plan: ref(null),
      position: ref(null),
      arrange: ref(null),
      modalGPS: ref(null),
      formRef: ref(),
      orderNum: '0',
      loading: false,
      detailsloading: false,
      mapAddress: {
        latLon: '',
        endAddress: '',
        startAddress: ''
      },

      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total, range) => `共 ${total} 条`
      },

      formState: {
        startAddress: {},
        start: [],
        endAddress: {},
        end: [],
        vehicleNo: '',
        driverName: '',
        carrierName: '',
        carrierMobile: '',
        carNo: '',
        truckStatus: { label: '', value: '' }
      },
      listData: [],
      columns: [
        {
          title: '操作',
          dataIndex: 'operation',
          width: 100,
          slots: {
            customRender: 'operation'
          }
        },
        {
          title: '安排时间',
          dataIndex: 'createTime',
          key: 'createTime'
          // ellipsis: true
        },
        {
          title: '发车时间',
          dataIndex: 'departureTime',
          key: 'departureTime'
          // ellipsis: true
        },
        {
          title: '车牌',
          width: 100,
          dataIndex: 'carNo',
          key: 'carNo',
          slots: {
            customRender: 'carNo'
          }
        },
        {
          title: '类型',
          width: 60,
          dataIndex: 'truckType.label',
          key: 'truckType.label'
        },
        {
          title: '司机信息',
          dataIndex: 'driver',
          width: 150,
          key: 'driver',
          slots: {
            customRender: 'driver'
          }
        },
        {
          title: '状态',
          width: 80,
          dataIndex: 'truckStatus',
          key: 'truckStatus',
          slots: {
            customRender: 'Status'
          }
        },
        {
          title: '派车人',
          width: 60,
          dataIndex: 'dispatcher',
          key: 'dispatcher'
          // ellipsis: true
        },
        {
          title: '是否到达',
          width: 50,
          dataIndex: 'isArrived.label',
          key: 'isArrived.label'
          // ellipsis: true
        },
        {
          title: '线路名称',
          dataIndex: 'lineName',
          width: 120,
          key: 'lineName'
        },
        {
          title: '超期',
          dataIndex: 'overdueText',
          key: 'overdueText'
          // ellipsis: true
        },
        {
          title: '当前位置',
          dataIndex: 'nowLocation',
          key: 'nowLocation',
          slots: {
            customRender: 'nowLocation'
          }
          // ellipsis: true
        },
        {
          title: 'gps状态',
          width: 110,
          dataIndex: 'gpsStatus',
          key: 'gpsStatus',
          slots: {
            customRender: 'gpsStatus'
          }
        },
        {
          title: 'gps类型',
          width: 110,
          dataIndex: 'gpsType.label',
          key: 'gpsType.label'
        },
        {
          title: '已占',
          width: 50,
          dataIndex: 'usedParkPlace',
          key: 'usedParkPlace',
          ellipsis: true
        },
        {
          title: '空位',
          width: 50,
          dataIndex: 'vacancy',
          key: 'vacancy',
          ellipsis: true
        },
        {
          title: '大车总运费',
          dataIndex: 'freightCost',
          key: 'freightCost',
          ellipsis: true
        }
      ],
      form: {},
      formNowLocation: {},
      getname: ''

    })
    let map = ref()
    const createMap = ref => {
      map = new LKMap.Map('containerMap', {
        center: [109.128713, 31.383405],
        zoom: 4
      })
    }
    const lineMap = () => {
      var transit = new BMap.DrivingRoute(map, {
        renderOptions: {
          map: map,
          panel: 'r-result',
          enableDragging: false // 起终点可进行拖拽
        }
      })
      transit.search(state.mapAddress.startAddress, state.mapAddress.endAddress)
    }
    // const audio = new Audio(orderMp3)
    const loadData = () => {
      state.loading = true
      bigPage({
        ...state.formState,
        current: state.pagination.current,
        size: state.pagination.pageSize,
        name: state.formState.carNo
      }).then(res => {
        if (res.code === 10000) {
          state.listData = res.data.records
          state.pagination.current = res.data.current
          state.pagination.pageSize = res.data.size
          state.pagination.total = res.data.total
        }
        state.loading = false
      })
    }
    onMounted(() => {
      loadData()
      // getHeight()
    })
    const handleTableChange = (pag, filters, sorter) => {
      state.pagination.current = pag.current
      state.pagination.pageSize = pag.pageSize
      loadData()
    }
    //  车辆轨迹
    const newTracking = (transTruckId) => {
      tracking(transTruckId).then((res) => {
        if (res.code === 10000) {
          state.newList = res.data
          const data = res.data
          createMap()
          if (data.length > 0) {
            markMap(data)
          } else {
            message.info('该车辆没有位置信息')
          }
        } else {
          message.error(res.msg)
        }
      })
    }
    // onMounted(newTracking)
    // 路书更替
    const markMap = (data) => {
      if (state.markers.length > 0) {
        state.markers.forEach(item => {
          item.remove()
        })
      }
      var drivingService
      drivingService = new LKMap.Driving_v2({
        costing: 'auto',	  // 路线规划方式  auto：轿车
        alternates: 0      //查找至多n条路线（n =< 3）
      })
      let coordinate = []
      data.forEach(item => {
        coordinate.push({ lat: Number(item.lat), lon: Number(item.lon) })
      })
      // 路线数组 data
      setTimeout(() => {
        if (coordinate.length >= 10) coordinate.pop()
        var params = {
          // locations: [{ lon: coordinate[0].lon, lat: coordinate[0].lat }, { lon: coordinate[coordinate.length - 1].lon, lat: coordinate[coordinate.length - 1].lat }],
          locations: coordinate,
          costing: 'auto',	  // 路线规划方式  auto：轿车
          alternates: 0      //查找至多n条路线（n =< 3）
        }
        console.log(params);
        drivingService.search(params, (status, result) => {
          console.log('驾车路线规划v2', status, result);
          showLine(result)	// 绘制线路
          addIcon()	// 添加起点坐标
        })
      }, 100)
      // 添加起点终点
      function addIcon () {
        state.markers = [];
        for (var i in coordinate) {
          var anchor = "center";
          var icon = new LKMap.Icon({
            size: new LKMap.Size(30, 30),
            image: 'https://lkimgyt.luokuang.com/1612506398655.png',
            imageSize: new LKMap.Size(30, 30),
            // imageOffset: new LKMap.Pixel(0, 0)
          });
          if (i == 0) {
            icon = new LKMap.Icon({
              size: new LKMap.Size(39, 48),
              image: 'https://lkimgyt.luokuang.com/1591930232132.png',
              imageSize: new LKMap.Size(39, 48),
              // imageOffset: new LKMap.Pixel(0, 0)
            });
            anchor = "bottom";
          } else if (i == coordinate.length - 1) {
            icon = new LKMap.Icon({
              size: new LKMap.Size(39, 48),
              image: 'https://lkimgyt.luokuang.com/1591930232633.png',
              imageSize: new LKMap.Size(39, 48),
              // imageOffset: new LKMap.Pixel(0, 0)
            });
            anchor = "bottom";
          }
          var position = new LKMap.LngLat(coordinate[i]['lon'], coordinate[i]['lat'])
          // var inforWindow = new LKMap.InforWindow({
          //   anchor: 'bottom',
          //   content: '<h3>箩筐地图</h3><p>地址：北京市朝阳区光华路光华SOHO</p><p>电话：010-66016602</p>',
          //   position: position,
          // });
          // inforWindow.open(map, position);
          state.markers[i] = new LKMap.Marker({
            position: position,
            icon: icon,
            label: {
              // item.gpsTime + '</br>' + '已到达' + item.latestLocation
              content: data[i]['gpsTime'] + '<br/>' + data[i]['latestLocation']
            },
            anchor: 'bottom',
          })
          state.markers[i].setMap(map);
          // state.markers[i].setContent("<div class='marker-demo'><h3>箩筐地图</h3><p>地址：北京市朝阳区光华路光华SOHO</p><p>电话：010-66016602</p></div>")
          // 绘制线路
        }
      }
      function showLine (result) {
        var polyline = []
        var color = ['#a9a4a4', '#3471e2', '#e44d33'];
        for (var i in result.routes) {
          polyline[i] = new LKMap.Polyline({
            path: result.routes[i].geometry.coordinates,
            strokeWeight: 8,
            lineJoin: 'round',
            lineCap: 'round',
            strokeColor: '#3471e2',
            showDir: true,
          });
          polyline[i].setMap(map);
        }
        setTimeout(() => {
          map.setFitView(null, {
            padding: {
              top: 100,
              bottom: 100,
              left: 0,
              right: 0,
            }
          });
        }, 500)
      }
    }
    const onClick = (recode, type) => {
      switch (type) {
        case 1:
          state.formNowLocation.transTruckId = recode.transTruckId
          break
        case 2:
          state.formRef.resetFields()
          break
        case 7:
          state.plan.ontransTruckId(recode.transTruckId)
          state.plan.onSwitch(true)
          state.plan.loadData()
          break
        case 8:
          loadData()
          break
        case 9:
          state.mapAddress.startAddress = recode.startAddress
          state.mapAddress.endAddress = recode.endAddress
          state.mapAddress.latLon = recode.lonLat
          state.mapShow = true
          if(recode.truckType) state.carType = recode.truckType.value
          createMap()
          // lineMap()
          //  车辆轨迹
          newTracking(recode.transTruckId)
          break
        case 10:
          state.position.oncarid(recode.transTruckId)
          break
        case 11:
          state.visiblemodal = false
          state.vehicleNo = null
          break
        case 12:
          state.visiblemodal = true
          state.detailsloading = true
          state.vehicleNo = recode.vehicleNo
          setTimeout(() => {
            state.detailsloading = false
          }, 1000)
          break
        default:
          message.success('出现错误' + type)
      }
    }

    const setStartAddress = (value, selectedOptions) => {
      state.formState.start = selectedOptions
      state.formState.startAddress.province = value[0]
      state.formState.startAddress.city = value[1]
      state.formState.startAddress.area = value[2]
    }

    const setEndAddress = (value, selectedOptions) => {
      state.formState.end = selectedOptions
      state.formState.endAddress.province = value[0]
      state.formState.endAddress.city = value[1]
      state.formState.endAddress.area = value[2]
    }
    const fullTable = () => {
      if (state.tableStyle.width === '100%') {
        state.tableStyle.width = 60 + '%'
      } else {
        state.tableStyle.width = 100 + '%'
      }
    }
    onMounted(fullTable)
    const positionShowModalGPS = () => {
      state.modalGPS.onSwitch(true)
    }
    const exportData = () => {
      truckPageExport({
        ...state.formState,
        current: state.pagination.current,
        size: state.pagination.pageSize,
        name: state.formState.carNo
      }, '车辆跟踪').then((res) => {

      })
    }
    return {
      exportData,
      onClick,
      loadData,
      createMap,
      handleTableChange,
      newTracking,
      setEndAddress,
      setStartAddress,
      ...toRefs(state),
      fullTable,
      positionShowModalGPS
    }
  }
}
</script>
<style lang="less" scoped>
.f1ab18 {
  color: red;
}

.d89f8 {
  color: #5d89f8;
}

.cb32f {
  color: #9cb32f;
}

.lv {
  color: #34b969;
}

.ee5c23 {
  color: #ee5c23;
}

.e3e3e3 {
  color: #e3e3e3;
}

.f47920 {
  color: red;
}

.e {
  color: #40835e;
}

.cd7 {
  color: #317cd7;
}

.ant-col {
  margin-bottom: 10px;
}

.position {
  background-color: #34b969;
  border: 0px;
}
</style>
